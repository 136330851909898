import { Typography } from "antd"
import { Header } from "../components/Header"
import { css } from "@emotion/css"
import { Container } from "../components/Container"
import { useParams } from "react-router-dom"
import { getPackageNamesByModule } from "../api/site"
import { useEffect, useState } from "react"

const { Title, Text, Paragraph } = Typography

export const ModulePage = () => {
  const { name } = useParams();
  const [ loading, setLoading ] = useState(true)
  const [ pkgs, setPkgs ] = useState<string[]>([])

  console.log("name is: ", name)

  useEffect(() => {
    document.title = `${name} - nomodulenamed`
    if (name) {
      getPackageNamesByModule(name).then((pkgs) => {
        setPkgs(pkgs || [])
        setLoading(false)
      }).catch((err) => {
        console.error(err)
        setLoading(false)
      })
    }
  }, [name, setPkgs])

  const TextStyle = css`
    font-size: 1.4rem;
    font-weight: 400;
  `;

  const readableOr = (pkgs: string[]) => {
    return pkgs.length > 1 ? (pkgs.slice(0, pkgs.length-1).join(", ") + " or " + pkgs[pkgs.length-1]) : pkgs[0]
  }

  return (
    <>
      <Header />
      <Container>
        <Paragraph><Title>Python Error: No module named {name}</Title></Paragraph>
      </Container>
      {pkgs.length ? (
        <Container className={css`
          font-size: 1.2rem;
        `}>
          <Paragraph><Text className={TextStyle}>This is probably because you don't have package {readableOr(pkgs)} installed.</Text></Paragraph>
          <Paragraph><Text className={TextStyle}>You can install it in command line via pip:</Text></Paragraph>
          <pre className={css`
            padding: 3rem 1rem 0;
            border-radius: 6px;
            background-image: url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" width="54" height="14" viewBox="0 0 54 14"><g fill="none" fillRule="evenodd" transform="translate(1 1)"><circle cx="6" cy="6" r="6" fill="%23FF5F56" stroke="%23E0443E" strokeWidth=".5" /><circle cx="26" cy="6" r="6" fill="%23FFBD2E" stroke="%23DEA123" strokeWidth=".5" /><circle cx="46" cy="6" r="6" fill="%2327C93F" stroke="%231AAB29" strokeWidth=".5" /></g></svg>');
            background-repeat: no-repeat;
            background-position: 16px 16px;
            box-shadow: 5px 5px 15px 0px rgba(50, 50, 50, 0.75);
            max-width: 600px;
            background-color: #F9F9F9;
          `}>
            {pkgs.map((pkg, i) => (
              <Title key={i} level={4} className={css`padding-bottom: 1rem;`}>
                <span className={css`color: #bb8013;`}>pip </span>
                <span className={css`color: #5E5E5E;`}>install </span>
                <span className={css`color: #9F1F5A;`}>{pkg}</span>
              </Title>
            ))}
          </pre>
        </Container>
      ) : (
        <Container>
          <Paragraph><Text className={TextStyle}>{ loading ? "laoding ..." : "not found" }</Text></Paragraph>
        </Container>
      )}
    </>
  )
}
